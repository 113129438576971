<template>
    <div class="pt-7 container-fluid">
        <div class="col-xl-8">
            <card>
                <b-row align-v="center" slot="header">
                    <b-col cols="8">
                        <h3 class="mb-0">{{$t('general.my_wallet')}}</h3>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <table class="table table-bordered" v-if="balances.amount!==undefined">
                            <thead>
                                <tr>
                                    <th v-for="(item,index) in balances.amount" :key="'header-'+index" class="toUpperCase text-center">{{index}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td v-for="(item,index) in balances.amount" :key="'body-'+index" class="text-center">{{item}} {{index | getCurrency}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </b-col>
                    <b-col cols="12">
                        <h6 class="modal-title mt-4 mb-0">{{$t('general.balance_history')}}</h6>
                        <hr class="my-4">
                        <table class="table table-bordered" v-if="balances.list!==undefined">
                            <thead>
                                <tr>
                                    <th>{{$t('general.date')}}</th>
                                    <th>{{$t('general.amount')}}</th>
                                    <th>{{$t('general.type')}}</th>
                                    <th>{{$t('general.payment_method')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in balances.list" :key="'list-'+index">
                                    <td>{{item.createdAt | getDate}}</td>
                                    <td>{{item.amount}} {{item.currency | getCurrency}}</td>
                                    <td :class="(item.type=='debt'?'text-danger':'text-success')">{{$t('general.balance_type_'+item.type)}}</td>
                                    <td>{{$t('general.'+item.paymentMethod)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
            </card>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

let self;

export default {
    components: {},
    computed: {
        ...mapGetters({ profile: 'getProfile', balances: 'getBalances'})
    },
    data() {
        return {
        };
    },
    methods: {
    },
    async mounted() {
    },
    created() {
        self = this;
    },
};
</script>